@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --defaultWhiteColor: #ffffff;
    --defaultPurple: #02007A;
    --darkPurple: #54007A;
    --lightPurple: #dfd0e7;
    --defaultGrey: #F7F7F7;
    --defaultBlack: #000;
    --landingBlack: #202020;
    --LightPink: #FCF5FF;
    --GreyLight: #D9D9D91A;
    --GreyLight2: #C5C4C8;
    --GreyLight3: #FAFAFA;
    --GreyLight4: #0701141A;
    --inputBackGrey: #E4E4E4;
    --DarkGrey: #606060;
    --ThemeColor: #F5F5F5;
    --BlueColor: #2579F7;
    --OrangeColor: #F99C47;
    --SkyBlue: #007AFF;
    --MainPurple: #6825F7;
    --Pink: #C325FF;
    --LinkedIn: #0077B5;
    --Red: red;
    --Green: green;
  }

  *,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }

  /* Style the scrollbar track */
  /* ::-webkit-scrollbar-track {
    background-color:var(--MainPurple);
  } */

  /* Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: var(--darkPurple);
    border-radius: 2px;
  }

  /* Set the width of the scrollbar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }

  h1 {
    @apply text-title36;
  }

  h2 {
    @apply text-title24;
  }

  h3 {
    @apply text-title20;
  }

  h4 {
    @apply text-description;
  }
}

@layer components {
  .landing_header {
    @apply fixed top-4 left-1/2 transform -translate-x-1/2 w-[90%] h-24 shadow-landing_header_shadow z-50 bg-White rounded-20;
  }

  .common_container {
    @apply w-[90%] mx-auto
  }

  .landing_menus {
    @apply text-Black text-description font-Poppins
  }
  .landing_menus:hover{
    @apply text-DarkPurple
  }
  .landing_menus_active{
    @apply text-DarkPurple font-semibold
  }

  .landing_button {
    @apply bg-landing_button text-White font-[500] h-12 text-para rounded-5 lg:px-4 xl:px-7
  }

  .landing_button:hover{
    @apply bg-custom-gradient transition ease-in-out duration-1000 hover:scale-105
  }
  .landing_input {
    @apply bg-White border-none h-12 rounded-10
  }

  .landing_input::placeholder {
    /* @apply text-Black */
  }
  .awesome_box{
    @apply border-[3px] bg-[#fbf8fb] text-center border-DarkPurple rounded-45 h-130 xl:h-150 w-130 xl:w-150
  }

  .logo_ {
    @apply h-[60px] md:h-[80px] !important;
  }

  .titleExtraLarge {
    @apply text-title24 lg:text-title32 xl:text-title36 font-Poppins;
  }

  .titleLarge {
    @apply text-title24 md:text-title30 font-Poppins;
  }

  .titleMedium {
    @apply text-title20 md:text-title24 font-semibold font-Poppins;
  }

  .titleSmall {
    @apply text-para lg:text-title20 font-semibold font-Poppins;
  }

  .paragraph {
    @apply text-para xl:text-description font-Poppins;
  }

  .paragraphSmall {
    @apply text-para md:text-descriptionSmall font-semibold font-Poppins;
  }

  .description {
    @apply text-para font-normal text-DarkGrey font-Poppins
  }

  .custom-container {
    @apply w-95 m-auto;
  }

  .header {
    @apply bg-White shadow-customShadow h-[100px] sticky top-0 z-50;
  }

  .content_common {
    @apply bg-GreyLight3 rounded-10 p-2 md:px-5 min-h-[750px]
  }

  .gradient-button {
    @apply bg-custom-gradient text-White text-para h-10 font-bold;
  }

  .common-button {
    @apply h-14 w-full md:w-64 font-bold text-para md:text-description font-Poppins;
  }

  .gradient-button:hover {
    @apply bg-gradient-to-r from-DarkPurple to-Purple border-none text-White !important;
  }

  .outline-button {
    @apply rounded-lg p-[2px] bg-custom-gradient;
  }

  .social-icon {
    @apply h-9 !important;
  }

  .auth-form {
    @apply md:mt-8 w-full md:w-700;
  }

  .auth-main-div {
    @apply py-5 px-5 md:px-0
  }

  .inputs-container {
    @apply md:px-8 py-5
  }

  .input-box {
    @apply h-12 bg-GreyLight rounded-5 border-2 border-inputBorder font-Poppins;
  }

  .input-box::placeholder {
    @apply text-para;
  }

  .back-box {
    @apply flex items-center justify-between bg-LightPink py-5 rounded-10 px-3
  }

  .content_style {
    @apply bg-ThemeColor overflow-y-auto p-10 relative;
  }

  .text_truncate {
    @apply overflow-hidden truncate whitespace-nowrap
  }

  .notification_button {
    @apply text-White font-Poppins font-semibold h-9
  }

  .animate_tab {
    @apply transition-all duration-500 ease-in
  }
  .tutorial_paren_div{
    @apply w-3/5
  }
}

.ant-input-affix-wrapper .ant-input-clear-icon{
  @apply text-[21px] text-DarkGrey
}

.ant-progress .ant-progress-inner {
  @apply !bg-DarkPurple
}

.ant-switch.ant-switch-checked {
  @apply bg-DarkPurple
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  @apply bg-DarkPurple
}

.ant-layout-header {
  @apply p-0 md:p-12 !important
}

.ant-menu-inline .ant-menu-item {
  @apply h-12 px-0
}

.ant-menu-item {
  @apply ps-0 md:ps-5 !important
}

.ant-popover .ant-popover-inner {
  @apply p-0
}

.ant-input-affix-wrapper>input.ant-input {
  @apply font-Poppins;
}

.ant-image .ant-image-img {
  @apply w-auto;
}

.ant-input-affix-wrapper>input.ant-input::placeholder {
  @apply text-DarkGrey text-para;
}

.ant-input-outlined:focus {
  @apply border-2 border-inputBorder shadow-none;
}

.ant-input-outlined:hover {
  @apply border-2 border-inputBorder;
}

.ant-input-outlined:focus-within {
  @apply border-2 border-inputBorder;
}

.ant-form-item .ant-form-item-explain-error {
  @apply text-left
}

.ant-modal-root .ant-modal-mask {
  @apply bg-ModalBack
}

.otp_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5px;
}

.otp_input_container {
  margin-top: 0rem !important;
  margin: 0.8rem;
  padding: 0rem;
  width: 3rem !important;
  height: 3.8rem !important;
  font-size: 2rem;
  color: var(--defaultPurple) !important;
  border: 2px solid var(--defaultPurple) !important;
  /* border-color: var(--defaultPurple) !important; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.2rem;
}

.carousel-item {
  padding: 0 5px;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 100% !important;
  /* border: 2px solid var(--defaultPurple) !important; */
  background-color: var(--defaultPurple) !important;
}

.ant-carousel .slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  background-color: var(--defaultPurple) !important;
  /* border: 2px solid var(--defaultPurple) !important; */
  border-radius: 100% !important;
}

.ant-carousel .slick-dots li.slick-active {
  width: 14px !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: -38px !important;
}


@media (max-width:1600px) {
  .myAside {
    @apply min-w-[20%] !important
  }
  .tutorial_paren_div {
    @apply w-[70%]
  }
}

@media (max-width:1260px) {
  .tutorial_paren_div {
    @apply w-95
  }
  .myAside {
    @apply min-w-[20%] !important
  }
}

@media (max-width:991px) {
  .tutorial_paren_div {
    @apply w-[70%]
  }
  .myAside {
    @apply min-w-[5%] !important
  }
}